/**
 * This code was generated by Builder.io.
 */
import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/icons/logo_name.svg";
import Facebook from "../assets/icons/facebook_red.svg";
import Youtube from "../assets/icons/youtube_red.svg";
import Instagram from "../assets/icons/instagram_red.svg";

function Footer() {
  return (
    <footer className="w-full bg-white pt-[120px] pb-[180px]">
      <div className="max-w-[1280px] mx-auto px-8 w-full">
        <div className="flex justify-between items-start gap-20 md:flex-row flex-col">
          <div className="flex-shrink-0">
            <Link to="/">
              <img src={logo} alt="Logo" />
            </Link>
          </div>
          
          <div className="flex flex-col md:flex-row gap-8 md:gap-10 justify-end font-body-02">
            <div className="flex flex-col gap-2 md:gap-4 text-red-600 iphone:w-[180px] w-[130px]">
              <Link to="/about">About</Link>
              <Link to="/press">Press</Link>
              <Link to="/events">Events</Link>
            </div>
            
            <div className="flex flex-col gap-2 md:gap-4 text-red-600 iphone:w-[180px] w-[130px]">
              <Link to="/search">Search</Link>
              <Link to="/archive">Search Archive</Link>
              <Link to="/editorial">Expert Commentary</Link>
            </div>
            
            <div className="flex flex-col gap-2 md:gap-4 text-red-600 iphone:w-[200px] w-[130px]">
              <div className="flex flex-col gap-2 text-red-600">
                <Link to="/support">Partner with us</Link>
                <Link to="/contact">Contact</Link>
              </div>
              
              <div className="flex gap-2 md:gap-4 mt-8">
                <Link to="https://www.facebook.com/firewallcafe" aria-label="Facebook">
                  <img src={Facebook} alt="Facebook logo" className="w-6" />
                </Link>
                <Link to="https://www.youtube.com/channel/UCMTAKSSmI9iKD7a3GB1JIrA" aria-label="Youtube">
                  <img src={Youtube} alt="Youtube logo" className="w-6" />
                </Link>
                <Link to="http://instagram.com/firewallcafe" aria-label="Instagram">
                  <img src={Instagram} alt="Instagram logo" className="w-6" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
