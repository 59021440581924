import React from 'react';

const ArrowRight = ({ fill = "black", className = "" }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <mask id="mask0_64_787" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#mask0_64_787)">
      <path d="M16.6116 12.75H5.25C5.03718 12.75 4.85898 12.6782 4.7154 12.5346C4.5718 12.391 4.5 12.2128 4.5 12C4.5 11.7872 4.5718 11.609 4.7154 11.4654C4.85898 11.3218 5.03718 11.25 5.25 11.25H16.6116L13.3192 7.95769C13.1705 7.80897 13.0971 7.63493 13.099 7.43558C13.1009 7.23623 13.1743 7.05899 13.3192 6.90386C13.4743 6.74874 13.6525 6.66862 13.8538 6.66349C14.0551 6.65835 14.2333 6.73335 14.3884 6.88849L18.8672 11.3673C18.9608 11.4609 19.0269 11.5596 19.0653 11.6635C19.1038 11.7673 19.123 11.8795 19.123 12C19.123 12.1205 19.1038 12.2327 19.0653 12.3365C19.0269 12.4404 18.9608 12.5391 18.8672 12.6327L14.3884 17.1115C14.2397 17.2603 14.0631 17.3337 13.8586 17.3317C13.6541 17.3298 13.4743 17.2513 13.3192 17.0962C13.1743 16.941 13.0993 16.7654 13.0942 16.5692C13.0891 16.3731 13.1641 16.1975 13.3192 16.0423L16.6116 12.75Z" fill={fill}/>
    </g>
  </svg>
);

export default ArrowRight; 